import React from 'react'
import { UrlInformation } from './Information'

interface UrlInfoProps {
  urlInformation: UrlInformation
}

const UrlInfo: React.FC<UrlInfoProps> = ({ urlInformation }) => {
  const { source, height, width } = urlInformation
  return (
    <iframe
      src={source}
      height={height || '100%'}
      width={width || '100%'}
      title="url-content"
      frameBorder="none"
      sandbox="allow-scripts allow-same-origin allow-modals allow-top-navigation allow-popups"
    ></iframe>
  )
}

export default UrlInfo
