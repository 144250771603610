import { useWindowWidth } from '@react-hook/window-size'
import React, { useState } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { Select } from '@rebass/forms'
import { trackHandler, useSupportedBrowser } from 'uneeq-react-core'
import { UnsupportedBrowser } from 'uneeq-react-ui'
import { Config } from '../../config'
import styles from './styles'
import { useTranslation } from 'react-i18next'

interface VideoProps {
  config: Config
}

interface ParagraphsProps {
  privacyUrl: string
}

const SophieVideo = ({ config }: VideoProps) => {
  const width = useWindowWidth()
  const smallScreen = width < 1024

  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      poster={
        smallScreen ? config.imagePortraitIdle : config.imageLandingPageIdle
      }
    >
      <source
        src={
          smallScreen
            ? config.videoPortraitIdleMP4
            : config.videoLandingPageIdleMP4
        }
        type="video/mp4"
      />
      <source
        src={
          smallScreen
            ? config.videoPortraitIdleWebm
            : config.videoLandingPageIdleMP4
        }
        type="video/webm"
      />
    </video>
  )
}

interface HomeProps {
  startSession: (token: string) => void
  config: Config | undefined | null
  lang: string
  setLang: (lang: string) => void
}

const Home: React.FC<HomeProps> = ({ startSession, config, lang, setLang }) => {
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)
  const { t, i18n } = useTranslation()

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  const changeLanguage = (e: any) => {
    const { value } = e.target
    i18n.changeLanguage(value)
    setLang(value)
  }

  const Paragraphs = ({ privacyUrl }: ParagraphsProps) => (
    <>
      <Text as="p">
        {t('HomePage.privacy1')}{' '}
        <Text
          as="a"
          href={privacyUrl}
          target="_blank"
          rel="noreferrer noopener"
          sx={styles.privacyPolicyLink}
        >
          {t('HomePage.privacyPolicy')}
        </Text>
        {t('HomePage.privacy2')}
      </Text>
    </>
  )

  const StartButton = ({ sx }: any) => (
    <Button
      id="lets-chat-btn"
      variant="outline"
      onClick={() => {
        trackHandler(startSession, 'lets-chat-btn')(true)
      }}
      sx={{ ...styles.actionButton, ...sx }}
    >
      {t('HomePage.letsChat')}
    </Button>
  )

  const LangSelectButton = ({ sx }: any) => {
    return (
      <Select
        id="language-select"
        name="language-select"
        defaultValue={lang}
        onChange={changeLanguage}
        sx={sx}
      >
        {i18n.languages.map(language => {
          return (
            <option
              key={language}
              value={language}
              style={styles.dropdownOption}
            >
              {t(`HomePage.${language}Select`)}
            </option>
          )
        })}
      </Select>
    )
  }

  return config ? (
    <Flex sx={styles.mainContainer}>
      <Text sx={styles.mobileImSophie}>
        {`${t('HomePage.meet')} ${config?.avatarName}, ${t(
          'HomePage.ourDigAssistant'
        )}`}
      </Text>
      <SophieVideo config={config} />

      <Box sx={styles.textContainer}>
        <Box sx={styles.titleContainer}>
          <Text sx={styles.imSophieText}>
            {t('HomePage.meet')} {config?.avatarName}
          </Text>
        </Box>
        <Box sx={styles.videoContainer}>
          <video
            autoPlay={false}
            loop={true}
            playsInline={true}
            controls={true}
            style={{ width: '335px', objectFit: 'cover' }}
            poster={config?.introVideoThumbnail}
          >
            <source src={config?.introVideo} type="video/mp4" />
            {t('HomePage.loading')}
          </video>
        </Box>
        <Text sx={styles.subtitle}>{config.landingPageSubtitle}</Text>
        <StartButton sx={styles.startButton} />

        <Box
          sx={{ display: ['block', 'block', 'block', 'block', 'none', 'none'] }}
        >
          <LangSelectButton
            sx={{
              ...styles.actionButton,
              ...styles.languageDropdown
            }}
          />
        </Box>

        <Flex
          sx={{
            ...styles.paragraphContainer
          }}
        >
          <Paragraphs privacyUrl={config.privacyPolicyLink} />
        </Flex>

        <Flex sx={styles.actionsContainer}>
          <Box>
            <StartButton />
          </Box>
          <Box>
            <LangSelectButton
              sx={{
                ...styles.actionButton,
                ...styles.languageDropdown
              }}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  ) : (
    <></>
  )
}

export default Home
