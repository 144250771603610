import React from 'react'
import { Text } from 'rebass'
import { FatalError } from 'uneeq-react-ui'

interface ErrorDialogProps {
  restart: () => void
}
const ErrorDialog: React.FC<ErrorDialogProps> = ({ restart }) => {
  return (
    <FatalError
      clearError={restart}
      errorTitle="Uh oh, looks like something went wrong. Sorry about that! Please try again later"
      errorButtonText="Close"
    >
      <Text />
    </FatalError>
  )
}

export default ErrorDialog
