import downloadPdf from './app/downloadPdf'
import { getClientConfig } from './api'

export interface Config {
  debug: boolean
  url: string
  conversationId: string
  tokenUrl: string
  talkJsUrl: string
  usePasscode: boolean
  recaptchaSiteKey: string
  playWelcome: boolean
  sendLocalVideo: boolean
  customData: {}
  tapThreshold: number
  timeout: number
  timeoutWarning: number
  avatarAspect: number
  avatarPosition: number
  informationInTranscript: boolean
  showEscalationForm: boolean
  emptyTranscriptThreshold: number
  avatarName: string
  talkJSid: string
  videoPermissionsMP4?: string
  videoPermissionsWebm: string
  videoPortraitIdleMP4: string
  videoPortraitIdleWebm: string
  videoLandingPageIdleMP4: string
  videoLandingPageIdleWebm: string
  imagePortraitIdle: string
  imageLandingPageIdle: string
  introVideo: string
  introVideoThumbnail: string
  privacyPolicyLink: string
  loadingTips?: string[]
  landingPageSubtitle: string
  mainMenuButtonText: string
  mainMenuButtonImage: string
  mainMenuButtonUtterance: string
  downloadPdf: (
    type: 'transcript' | 'savedItems',
    items: any,
    sessionId: string
  ) => Promise<void>
  language: string
  locale: string
}

let environment = {}
// You can override default settings for different environments
// by setting REACT_APP_CONFIG environment variable
if (process.env.REACT_APP_CONFIG === 'EXAMPLE') {
  console.info('config: example')
  environment = {
    conversationId: 'OTHER'
  }
} else {
  console.info('config: default')
}

const baseConfig = {
  debug: true,

  // Is a passcode needed to obtain a token? Show a passcode screen so the user can provide it.
  usePasscode: false,
  // Recaptcha - if used with passcode
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY!,

  // welcome can be suppressed during dev with REACT_APP_NO_WELCOME=1
  playWelcome: !process.env.REACT_APP_NO_WELCOME,

  sendLocalVideo: false,
  customData: {},

  // When holding down spacebar, durations shorter that this will trigger
  // a message like "Keep holding spacebar while you talk"
  tapThreshold: 700, // ms

  // How long can the user be inactive before timeout
  timeout: 1800 * 1000, // ms
  // How close to the end of the timeout should we show the warning
  timeoutWarning: 180 * 1000, // ms
  // e.g. timeout=90sec, timeoutWarning=30sec - after 60 secs of inactivity warning will show, after 90 secs sessions ends

  // If the avatar is not centred within the video avatarPosition needs to be changed.
  // This will ensure correct positioning narrow (mobile) screens
  avatarAspect: 16 / 9, // video aspect ratio
  avatarPosition: 0.5, // Where is the avatar in the video frame (0.5 = center, 0.33 = 1/3 from the left)

  informationInTranscript: false,

  // show we show the contact form after Feedback?
  showEscalationForm: false,

  // How many empty transcripts before an error is shown
  emptyTranscriptThreshold: 3,

  // Default Client Values
  url: '',
  conversationId: '',
  tokenUrl: '',
  talkJsUrl: '',
  avatarName: '',
  talkJSid: '',
  videoPermissionsMP4: '',
  videoPermissionsWebm: '',
  videoPortraitIdleMP4: '',
  videoPortraitIdleWebm: '',
  videoLandingPageIdleMP4: '',
  videoLandingPageIdleWebm: '',
  imagePortraitIdle: '',
  imageLandingPageIdle: '',
  introVideo: '',
  introVideoThumbnail: '',
  privacyPolicyLink: '',
  landingPageSubtitle: '',
  loadingTips: [],
  googleAPIKey: '',
  mainMenuButtonText: '',
  mainMenuButtonImage: '',
  mainMenuButtonUtterance: '',
  downloadPdf,
  language: 'en',
  locale: 'en-US',

  // Optional - track events
  // analytics: (label: string, action?: string) => {
  //   console.info('analytics event:', { label, action })
  //   // Send the event to your analytics backend
  //   // Example Google Universal Analytics (gtag.js)
  //   // https://developers.google.com/analytics/devguides/collection/gtagjs
  //   gtag('event', 'action', {
  //     event_category: 'my-category',
  //     event_label: label
  //   })
  // },

  ...environment
}

export const createConfig = async (lang: string): Promise<any> => {
  const clientConfigValues = await getClientConfig(lang)
  return { ...baseConfig, ...clientConfigValues }
}
