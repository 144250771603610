import React, { useContext, useState } from 'react'
import { Flex, Box, Button, Heading } from 'rebass'
import { Input } from '@rebass/forms'
import styles from './styles'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useTranslation } from 'react-i18next'

const AutocompleteDropdown: React.FC = () => {
  const { dispatch, sendText } = useContext(UneeqContext)
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [showDropdown, setShowDropdown] = useState(true)

  const { googleAPIKey } = useUneeqState()

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = useGoogle({
    apiKey: googleAPIKey || 'AntiErrorKey',
    debounce: 100
  })

  const chooseOption = (prediction: string) => {
    setValue(prediction)
    setShowDropdown(false)
  }

  const close = () => {
    dispatch({ type: 'emptyOnScreenInfoInformation' })
    dispatch({ type: 'collapseExpandedInfo' })
    dispatch({ type: 'hideExpandedAutocomplete' })
    dispatch({ type: 'openEnterAddress', payload: false})
  }

  const submitValue = () => {
    sendText(value)
    close()
  }

  const submitCancel = () => {
    sendText('cancel')
    close()
  }

  const onInputChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = evt => {
    setShowDropdown(true)
    getPlacePredictions({ input: evt.target.value })
    setValue(evt.target.value)
  }

  return (
    <Flex
      sx={styles.container}
      key="autocomplete-dropdown"
      flexDirection="column"
    >
      <Heading color="white" textAlign="center">
        {t('Autocomplete.title')}
      </Heading>
      <Flex sx={styles.inputWrapper} flexWrap="wrap">
        <Input
          sx={styles.textInput}
          value={value}
          placeholder={t('Autocomplete.placeholder')}
          onChange={onInputChange}
        />
        <Button
          variant="primaryInverted"
          sx={styles.submitButton}
          onClick={submitValue}
        >
          {t('Autocomplete.submit')}
        </Button>
        <Button
          variant="primaryInverted"
          sx={styles.cancelButton}
          onClick={submitCancel}
        >
          {t('Autocomplete.cancel')}
        </Button>
      </Flex>
      <Flex sx={styles.optionsContainer} flexDirection="column">
        {showDropdown &&
          !isPlacePredictionsLoading &&
          placePredictions.map(prediction => (
            <Box
              key={prediction.description}
              sx={styles.option}
              onClick={() => chooseOption(prediction.description)}
            >
              {prediction.description}
            </Box>
          ))}
      </Flex>
    </Flex>
  )
}

export default AutocompleteDropdown
