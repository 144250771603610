import { DigitalHuman, PasscodeOverlay } from 'uneeq-react-ui'
import React, { useState, useRef, useEffect } from 'react'
import { Box } from 'rebass'

import { ThemeProvider } from 'emotion-theming'

import theme from '../theme'
import { createConfig, Config } from '../config'
import Home from './Home'
import assets from './assets'

import { testState } from 'uneeq-react-core'
import SessionTimedOut from './SessionTimedOut'
import ErrorDialog from './ErrorDialog'
const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  minHeight: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#000000',
  backgroundImage: ['none', 'none', 'none', 'none', `none`, `none`],
  overflow: 'hidden',
  position: 'absolute'
}

const App = () => {
  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')

  // passcode is an overlay, not a step
  const [showPasscode, setShowPasscode] = useState(false)
  const [hasError, setHasError] = useState(false)

  const [config, setConfig] = useState<Config | null>()
  const [lang, setLang] = useState('en')

  useEffect(() => {
    ;(async () => {
      try {
        const clientConfig = await createConfig(lang)
        if (clientConfig) {
          document.title = clientConfig.avatarName || 'Digital Human'
          assets.video.permissions = {
            permissionsMP4: clientConfig.videoPermissionsMP4,
            permissionsWebm: clientConfig.videoPermissionsWebm
          }
        }
        setConfig(clientConfig)
      } catch (error) {
        console.error(error)
        setHasError(true)
      }
    })()
  }, [lang, setConfig, setHasError])

  const tokenRef = useRef<string>()
  const start = () => {
    // Show passcode overlay or go straight to digitalHuman
    if (config?.usePasscode) {
      setShowPasscode(true)
    } else {
      goTo('digitalHuman')
    }
  }

  const startWithToken = (token: string) => {
    tokenRef.current = token
    goTo('digitalHuman')
    setShowPasscode(false)
  }

  const restart = () => goTo('welcome')

  const exitDialog = () => setHasError(false)

  const mapLoadingTips = (tipsArray: string[] | undefined) => {
    return tipsArray
      ? tipsArray.map(tip => {
          return {
            title: tip,
            showOnDesktop: true,
            showOnMobile: true
          }
        })
      : [
          {
            title: 'Loading...',
            showOnDesktop: true,
            showOnMobile: true
          }
        ]
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...backgroundStyle,
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {step === 'digitalHuman' && config ? (
          <DigitalHuman
            assets={assets}
            config={config}
            token={tokenRef.current}
            loadingTips={mapLoadingTips(config.loadingTips)}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => goTo('restart')}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home
            startSession={start}
            config={config}
            lang={lang}
            setLang={setLang}
          />
        )}
        {showPasscode && config && (
          <PasscodeOverlay
            start={startWithToken}
            close={() => setShowPasscode(false)}
            config={config}
          />
        )}
        {hasError && <ErrorDialog restart={exitDialog} />}
      </Box>
    </ThemeProvider>
  )
}

export default App
