import React, { useEffect, useContext, useState } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import Information, { InformationItem, UrlInformation } from './Information'
import { Box } from 'rebass'
import { motion, AnimatePresence } from 'framer-motion'
import InformationExpanded from './InformationExpanded'
import { UneeqContext } from 'uneeq-react-core'
import styles from './styles'
import UrlInfo from './UrlInfo'

const MotionCard = motion.custom(Box)

const OnScreenInfo = () => {
  const {
    onScreenInfo,
    mobileInformationOpen,
    showExpandedAutocomplete
  } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { information } = onScreenInfo || {}
  const [urlInformation, setUrlInformation] = useState<UrlInformation>()

  useEffect(() => {
    if (
      information?.find(({ type }: { type: string }) => type === 'autocomplete')
    ) {
      dispatch({ type: 'expandInformation' })
      dispatch({ type: 'showExpandedAutocomplete' })
    }
  }, [dispatch, information])

  useEffect(() => {
    setUrlInformation(
      information?.find(
        (information: InformationItem) => information.type === 'url'
      ) as UrlInformation
    )
  }, [information])

  return (
    <>
      <InformationExpanded />
      {!showExpandedAutocomplete && (
        <Box
          sx={{
            ...styles.conatianer,
            ...(urlInformation && styles.urlInfo.containerOverwrite),
            display: !mobileInformationOpen
              ? ['none', 'none', 'none', 'none', 'flex', 'flex']
              : 'flex'
          }}
          id="onscreeninfo-container"
        >
          <AnimatePresence>
            {information && (
              <MotionCard
                sx={{
                  ...styles.card,
                  ...(urlInformation && styles.urlInfo.cardOverwrite),
                  background: onScreenInfo.infoBackground
                }}
                variant={!urlInformation ? 'card' : ''}
                initial={{
                  opacity: 0,
                  transform: 'translate(0px, 200px)'
                }}
                animate={{
                  opacity: 1,
                  transform: `translate(0px, 0px)`
                }}
                exit={{
                  opacity: 0,
                  transform: `translate(0px, -200px)`
                }}
              >
                {urlInformation ? (
                  <UrlInfo urlInformation={urlInformation} />
                ) : (
                  <Information information={information} />
                )}
              </MotionCard>
            )}
          </AnimatePresence>
        </Box>
      )}
    </>
  )
}

export default OnScreenInfo
