import { useContext, useEffect, useState } from 'react'
import Talk from 'talkjs'
import { UneeqContext } from 'uneeq-react-core'
import { isMobile } from 'react-device-detect'

declare global {
  interface Window {
    // Set session in window object, as per TalkJS documentation
    talkSession: Talk.Session | undefined
  }
}

interface ChatSession {
  appId: string
  users: Array<{
    id: string
    role: string
    name: string
    email: string
    photoUrl: string
    welcomeMessage: string
  }>
}

const useTalkJs = (avatarName: string, talkjsUrl: string, locale: string) => {
  const { sessionId, state } = useContext(UneeqContext)
  const { ready } = state

  const [popup, setPopup] = useState<Talk.Popup | undefined>(undefined)
  const [session, setSession] = useState<Talk.Session | undefined>(undefined)

  /**
   * Setup session & popup when sessionId changes
   */
  useEffect(() => {
    genTalkJsSession(sessionId, avatarName, talkjsUrl, locale).then(talkJsSession => {
      const { popup, session } = talkJsSession || {}

      if (!popup || !session) {
        return
      }

      setPopup(popup)
      setSession(session)
    })
  }, [sessionId, avatarName, talkjsUrl])

  /**
   * Mount the popup we are ready
   */
  useEffect(() => {
    if (!ready) {
      return
    }
    popup?.mount({ show: !isMobile })
  }, [ready, popup])

  return { popup, session }
}

/**
 * Get chat session information from TalkJS API Endpoint
 */
const getChatSession = async (
  url: string
): Promise<ChatSession | undefined> => {
  try {
    const result = await fetch(url)
    const data = result.json()

    return data
  } catch (error) {
    console.error(
      'Could not get chat user. Is your chat user service running?',
      error
    )
  }
}

/**
 * Generate the Talk popup, setting up users retrieved from the
 * chat session retrieved from the TalkJS API Endpoint
 */
const genTalkJsSession = async (
  sessionId: string,
  avatarName: string,
  talkjsUrl: string,
  locale: string
) => {
  if (!sessionId || !avatarName) {
    return
  }

  const [chatSession] = await Promise.all([
    getChatSession(talkjsUrl),
    Talk.ready
  ])

  const { users, appId } = chatSession || {}
  if (!users || !appId) {
    return
  }

  // Create a user for every user retrieved in the chat session API endpoint
  const _users = users.map(user => {
    const id = user.role === 'user' ? sessionId : user.id
    const { name, email, photoUrl, welcomeMessage } = user

    return new Talk.User({
      id,
      name,
      role: 'user',
      email,
      photoUrl,
      welcomeMessage,
      locale
    })
  })

  // Destroy the previous session if it previously existed
  window.talkSession?.destroy()

  // Here we are 'logging' the 'me' user in to TalkJS
  window.talkSession = new Talk.Session({
    appId,
    me: _users.find(user => user.id === sessionId) as Talk.User
  })

  // Here you define the conversation that all these user's will be joining
  const conversation = window.talkSession.getOrCreateConversation(sessionId)

  // Next, we add each of these users to that conversation
  _users.forEach(user => conversation.setParticipant(user))

  return {
    popup: window.talkSession.createPopup(conversation, {
      keepOpen: false
    }),
    session: window.talkSession
  }
}

export default useTalkJs
