import axios from 'axios'

interface ClientData {
  [index: string]: string | undefined
  uneeq_token_server?: string
  uneeq_url?: string
  talkjs_id?: string
  talkjs_info_server?: string
  uneeq_persona_id?: string
  avatarName?: string
  loading_tip_1?: string
  loading_tip_2?: string
  loading_tip_3?: string
  loading_tip_4?: string
  loading_tip_5?: string
  loading_tip_6?: string
  loading_tip_7?: string
  loading_tip_8?: string
  video_permissionsMP4?: string
  video_permissionsWebm?: string
  video_portrait_idleMP4?: string
  video_landing_page_idleMP4?: string
  video_portrait_idleWebm?: string
  video_landing_page_idleWebm?: string
  image_portrait_idle?: string
  image_landing_page_idle?: string
  intro_video?: string
  intro_video_thumbnail?: string
  privacy_policy_link?: string
  digital_human_intro?: string
  main_menu_button_text?: string
  main_menu_button_image?: string
  main_menu_button_utterance?: string
  googleAPIKey?: string
  locale?: string
}

const modifyURLDataQueryString = (uneeqTokenUrl: string) => {
  if (!uneeqTokenUrl) return ''

  const defaultValue = '102'
  const uneeqArray = uneeqTokenUrl.split('?')

  if (uneeqArray.length < 2) return `${uneeqTokenUrl}?data=${defaultValue}`

  const searchQuery = uneeqArray.pop()
  const uneeqQuery = new URLSearchParams(searchQuery)
  const urlQuery = new URLSearchParams(window.location.search)

  const urlDataValue = urlQuery.get('clientId')
  const uneeqDataValue = uneeqQuery.get('data')

  // Use any URL Data values then any provided by initial url
  const dataValue = urlDataValue || uneeqDataValue || defaultValue

  uneeqQuery.set('data', dataValue)
  return [...uneeqArray, uneeqQuery.toString()].join('?')
}

const mapResponseToConfig = (data: ClientData, language: string) => {
  // Change loading tips to be an array of tips
  const tips = []
  for (const val in data) {
    if (val.startsWith('loading_tip')) {
      tips.push(data[val])
    }
  }

  const tokenUrl = data.uneeq_token_server
    ? modifyURLDataQueryString(data.uneeq_token_server)
    : ''

  return {
    tokenUrl,
    url: data.uneeq_url,
    talkJSid: data.talkjs_id,
    talkJsUrl: data.talkjs_info_server,
    conversationId: data.uneeq_persona_id,
    avatarName: data.avatarName,
    googleAPIKey: data.googleAPIKey,
    videoPermissionsMP4: data.video_permissionsMP4,
    videoPermissionsWebm: data.video_permissionsWebm,
    videoPortraitIdleMP4: data.video_portrait_idleMP4,
    videoPortraitIdleWebm: data.video_portrait_idleWebm,
    videoLandingPageIdleMP4: data.video_landing_page_idleMP4,
    videoLandingPageIdleWebm: data.video_landing_page_idleWebm,
    imagePortraitIdle: data.image_portrait_idle,
    imageLandingPageIdle: data.image_landing_page_idle,
    introVideo: data.intro_video,
    introVideoThumbnail: data.intro_video_thumbnail,
    privacyPolicyLink: data.privacy_policy_link,
    landingPageSubtitle: data.digital_human_intro,
    mainMenuButtonText: data.main_menu_button_text,
    mainMenuButtonImage: data.main_menu_image,
    mainMenuButtonUtterance: data.main_menu_button_utterance,
    loadingTips: tips,
    language,
    locale: data.locale
  }
}

export const getClientConfig = async (lang: string) => {
  const query = new URLSearchParams(window.location.search)

  const clientId = query.get('client') || 'VIPKeyz'
  const language = lang || query.get('language') || 'en'
  const queryParams = new URLSearchParams({
    client: clientId,
    language: language
  })
  const url = `${process.env.REACT_APP_FRONTEND_INFO_URL}?${queryParams}`

  const response = await axios.get<ClientData>(url, {
    timeout: 5000
  })

  return mapResponseToConfig(response.data, language)
}
