export const styles = {
  container: {
    height: 400,
    width: '100%'
  },
  inputWrapper: {
    background: 'white'
  },
  textInput: {
    color: 'black',
    border: 'solid white 1px',
    flex: ['none', 'none', 'none', 'none', 3]
  },
  submitButton: {
    flex: 2
  },
  cancelButton: {
    backgroundColor: 'darkgrey',
    flex: 1
  },
  optionsContainer: {
    pt: 1
  },
  option: {
    border: 'solid black 1px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    padding: '2px 8px',
    '&:hover': {
      backgroundColor: 'primary',
      cursor: 'pointer'
    }
  }
}
export default styles
